html,
body {
  min-height: 100vh;
  background: #fff;
  transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #000000d9;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji,
    PingFangSC-Medium, PingFang SC;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}
